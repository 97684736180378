<template>
    <v-row no-gutters>
        <v-col cols="12">
            <ProductBar :organizationId="$route.params.organizationId" :coupon="coupon" class="mb-6" v-if="coupon"></ProductBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link> &gt;
                    <router-link :to="{ name: 'organization-search-product', params: { organizationId: this.$route.params.organizationId } }">Products</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="couponEmail">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption text-end grey--text text--darken-2 mb-0">Coupon Email {{ couponEmail.id }}</p>
                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>
                                Assigned Coupon <!-- Coupon Email -->
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <!-- <v-card-text>
                            <p class="text-caption mb-0 pb-0 mt-0">The following coupon information is PUBLIC and is shown to customers.</p>
                        </v-card-text> -->
                        <v-simple-table dense>
                            <template #default>
                                <tbody>
                                    <tr>
                                        <th style="min-width: 100px;">Email</th>
                                        <td style="width: 100%;">
                                            <EditableText :value="couponEmail.email" @input="saveCouponEmail" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th># times used</th> <!-- number of times this coupon has been applied -->
                                        <td>
                                            {{ couponEmail.activated }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Not before</th>
                                        <td>
                                            <!-- <EditableTextSelect :value="couponEmail.not_before" :items="yesnoChoices" @input="saveCouponEmailNotBefore" dense/> -->
                                            <!-- TODO: editable with date picker -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Not after</th>
                                        <td>
                                            <!-- <EditableTextSelect :value="couponEmail.not_after" :items="yesnoChoices" @input="saveCouponEmailNotAfter" dense/> -->
                                            <!-- TODO: editable with date picker -->
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <th>Alias</th>
                                        <td>
                                            <EditableText :value="couponEmail.alias" @input="saveCouponAlias" dense/>
                                        </td>
                                    </tr> -->
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableText from '@/components/EditableText.vue';
import ProductBar from '@/components/ProductBar.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';

export default {
    components: {
        EditableText,
        ProductBar,
        AccessDeniedOverlay,
    },
    data: () => ({
        organization: null,
        couponEmail: null,
        status: null,
        error: null,
        forbiddenError: false,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            couponTypeChoices: (state) => state.couponTypeChoices,
            // productPublishedChoices: (state) => state.productPublishedChoices,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
        isCreatePriceFormComplete() {
            return false;
        },
        // customerProductLink() {
        //     // TODO: get default site with possible custom hostname...  and the brandprofile, if needd...
        //     return "";http://customer.unicornsprings.test/brand/libertydns/product?id=06J5B62A4Z7WRCCBET4G
        // },
        yesnoChoices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
    },
    watch: {
        dialogEditBrandProfile(newValue) {
            if (newValue) {
                this.editableBrandProfileAlias = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('editableBrandProfileInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadCouponEmail() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadCouponEmail: true });
                const query = { id: this.$route.query.id };
                const response = await this.$client.organization(this.$route.params.organizationId).couponEmail.get(query);
                console.log(`editcoupon.vue: response ${JSON.stringify(response)}`);
                if (response?.item) {
                    this.couponEmail = response.item;
                    // this.sortableProductList = response.item.content?.upgrade_order ?? [];
                    // this.checkCoupon();
                } else if (response?.id) {
                    this.couponEmail = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load product', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadCouponEmail: false });
            }
        },
        async saveCouponAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveProductAttr: true });
                const response = await this.$client.organization(this.$route.params.organizationId).couponEmail.edit({ id: this.$route.query.id }, { [name]: value });
                console.log(`saveProductAttr: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.coupon, name, value);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit coupon' });
                }
            } catch (err) {
                console.error(`failed to edit coupon attr [${name}]: ${JSON.stringify(value)}`, err);
                if (err.response?.status === 409) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Coupon email already exists' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } finally {
                this.$store.commit('loading', { saveProductAttr: false });
            }
        },
        async saveCouponEmail(value) {
            this.saveCouponAttr('email', value);
        },
        async saveCouponEmailNotBefore(value) {
            await this.saveCouponAttr('not_before', value);
        },
        async saveCouponEmailNotAfter(value) {
            await this.saveCouponAttr('not_after', value);
        },
    },
    mounted() {
        this.loadOrganization();
        this.loadCouponEmail();
    },
};
</script>
